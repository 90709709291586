import React, { useEffect } from "react";
import type { AppProps } from "next/app";
import Script from "next/script";
import TagManager from "react-gtm-module";
import "../styles/globals.css";
import "antd/dist/antd.css";

const tagManagerArgs = {
  gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAB_MANAGER,
};

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <React.Fragment>
      <Script src="/static/iframeResizer.contentWindow.min.js" />
      <Script async src="https://w.appzi.io/w.js?token=4RmQA" />
      <Component {...pageProps} />
    </React.Fragment>
  );
}
export default MyApp
